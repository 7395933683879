import { Dialog, DialogContent, Grid, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TypeRaster } from 'common/defines/constants';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { postCreateAnalytics } from 'services/analytics/apiAnalyticsConfig.services';
import { getSettingsDataCroptypeById } from 'services/settings/settings.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface IDialogCreateAnalytics {
  levelId: string;
  name: TypeRaster;
  parentId: string;
  onClose: () => void;
}

export const DialogCreateAnalytics = ({ onClose, levelId, parentId, name }: IDialogCreateAnalytics) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const classes = useDialogCreateStyle();
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);
  const theme = useTheme();
  const { t } = useTranslation();

  const { cropType } = useAppSelector(mapViewSelector);
  const { data: getAnalyticById } = useQuery([QUERY_KEY.ANALYTIC_BY_ID, cropType], () =>
    getSettingsDataCroptypeById(cropType)
  );

  const dataTypeAnalytic = useMemo(() => {
    const options = [] as any;
    getAnalyticById?.data.analysisConfig
      .filter((item: any) => {
        return item.rasterType === name;
      })
      .forEach((jtem: any) => {
        options.push({ value: jtem.name, label: t(convertAnalyticNameToMultiLanguage(jtem.name)) });
      });
    return options;
  }, [getAnalyticById?.data.analysisConfig, name, t]);

  const createAnalyticMutation = useMutation((data: any) => postCreateAnalytics(data), {
    onSuccess: () => {
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
      toast.success('Create Analytics Configuration Successful', { toastId: 1 });
      onClose();
    },
    onError: (error: any) => {
      if (error.data.type === 'Analysis is exist') {
        toast.error('Please relabel the existing analytics before adding another one', { toastId: 1 });
      } else {
        toast.error('Create Analytics Configuration Error', { toastId: 1 });
      }
    },
  });

  return (
    <Dialog onClose={() => onClose()} open={true} className={classes.dialog} maxWidth="sm">
      <DialogContent
        sx={{
          '& .MuiDialogContent-root': {
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        }}>
        <Formik
          initialValues={{ name: undefined }}
          onSubmit={(values, { resetForm }) => {
            const paramData = {
              name: values.name,
              levelId,
              parentId,
            };
            createAnalyticMutation.mutate(paramData, {
              onSuccess: () => {
                resetForm();
              },
            });
          }}>
          {({ values, setFieldValue, handleSubmit, submitForm }) => (
            <form onSubmit={handleSubmit}>
              <Grid display="flex" alignItems="center" justifyContent="space-between">
                <InputLabel variant="standard" sx={{ fontSize: '14px' }} htmlFor="uncontrolled-native">
                  Type of Analytic
                </InputLabel>
                <Select
                  displayEmpty
                  value={values.name}
                  onChange={(event: any) => setFieldValue('name', event.target.value?.value)}
                  sx={{
                    width: '205px',
                    height: '37px',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey2),
                  }}>
                  {dataTypeAnalytic &&
                    dataTypeAnalytic.map((item: any) => (
                      <MenuItem value={item} key={item.name}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid display="flex" alignItems="center" mt={4} justifyContent="center" spacing={3}>
                <ButtonCustom
                  title="Cancel"
                  border="1px solid #3C4043"
                  colorLabel="#3C4043"
                  sx={{ width: '160px', height: '1.4375em', marginRight: '20px' }}
                  backgroundColor="#FFF"
                  onClick={() => onClose()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
                <ButtonCustom
                  title="Save"
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={!values.name || createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const useDialogCreateStyle = makeStyles(() => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '500px',
      padding: '10px',
    },
  },
}));
