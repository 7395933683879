import { Add } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ModalMessageInUserManagement, TypeModalDelete } from 'common/defines/clients';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteClientUserAdminByIdAxios,
  getUserListByPagination,
  queryMe,
  resetUserPasswordById,
} from 'services/clients/apiClient.services';
import UserProfile from '../UserProfile';

const flexValue = [4, 2, 1];

export const UserManagement = ({ isShowModalShare = true }) => {
  const queryClient = useQueryClient();
  const { clientId } = useParams();
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [clientIndex, setClientIndex] = useState('');
  const [clientName, setClientName] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const titleDialog = useRef<HTMLDivElement>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowPerPageOptions = [5, 10, 15, 20];
  const [modalMessage, setModalMessage] = useState<ModalMessageInUserManagement>();
  const [userId, setUserId] = useState('');
  const [canBecomeSuperUser, setCanBecomeSuperUser] = useState(false);
  const [ableToResetPassword, setAbleToResetPassword] = useState(false);
  const [ableToDelete, setAbleToDelete] = useState(false);
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [ableToAdd, setAbleToAdd] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    clientListTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
      height: '57px',
    },
    clientContainerName: {
      flex: flexValue[0],
      borderBottom: 'none',
      overflowX: 'hidden',
    },
    clientAvatar: {
      width: '33px',
      height: '33px',
      marginRight: '12px',
    },
    clientName: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
    },
    clientUpdateTime: {
      flex: flexValue[1],
    },
    clientStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flex: flexValue[2],
      color: theme.palette.primary.main,
      p: '5px 0',
      backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F3FDF7',
      borderRadius: '21px',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    clientActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: flexValue[2],
    },
    clientActionsIcon: {
      width: '16px',
      height: '16px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#9F9F9F',
    },
    clientShowTotal: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '20px 20px',
    },
  };

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);
  const currentUserId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const {
    data: userList,
    refetch,
    isLoading,
  } = useQuery(
    [QUERY_KEY.USER_DETAILS, page, rowsPerPage, searchClient, clientId],
    () => getUserListByPagination(page, rowsPerPage, searchClient, clientId || null),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (isSuperAdmin || isSuperUser) {
      setAbleToEdit(true);
      setAbleToAdd(true);
      setAbleToDelete(true);
      setAbleToResetPassword(true);
      return;
    }
    if (!userInfo?.data?.role) {
      setAbleToEdit(false);
      setAbleToAdd(false);
      setAbleToDelete(false);
      setAbleToResetPassword(false);
      return;
    }

    const permissionsData: string[] = [];
    // eslint-disable-next-line array-callback-return
    userInfo?.data?.role?.map((item: any) => {
      if (item.clientId === clientId) {
        permissionsData.push(...item.permissions);
      }
    });
    if (permissionsData.length === 0) return;
    permissionsData.forEach((item: any) => {
      switch (item) {
        case 'MANAGE_USERS_EDIT':
          setAbleToResetPassword(true);
          setAbleToEdit(true);
          break;
        case 'MANAGE_USERS_ADD':
          setAbleToAdd(true);
          break;
        case 'MANAGE_USERS_DELETE':
          setAbleToDelete(true);
          break;
        default:
          break;
      }
    });
  }, [clientId, isSuperAdmin, isSuperUser, userInfo, userList]);

  const modalDescription = useMemo(() => {
    if (modalMessage === ModalMessageInUserManagement.DELETE) {
      return {
        title: 'Delete User',
        description: (
          <>
            <Typography>Are you sure you want to delete {clientName}? This action cannot be undone.</Typography>
            <br />
            <Typography>
              Confirm by typing{' '}
              <Typography component="span" sx={{ color: '#EF4444' }}>
                {clientName}
              </Typography>{' '}
              in the field below.
            </Typography>
          </>
        ),
        saveLabel: 'Deactivate Account',
      };
    }
    if (modalMessage === ModalMessageInUserManagement.RESET_PASSWORD) {
      return {
        title: 'Reset user password?',
        description: (
          <>
            <Typography>Are you sure you want to require a password reset on next login for this user?</Typography>
            <Typography>
              This will prompt the user to create and confirm a new password the next time they attempt to login.
            </Typography>
          </>
        ),
        saveLabel: 'Reset Password',
      };
    }
    return {
      title: '',
      description: '',
      saveLabel: '',
    };
  }, [clientName, modalMessage]);

  const handleDelete = useMutation((id: string) => deleteClientUserAdminByIdAxios(id), {
    onSuccess: () => {
      setClientIndex('');
      queryClient.invalidateQueries(QUERY_KEY.USER_DETAILS);
      toast.success('User deleted successfully');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleUserResetPassword = useMutation((id: string) => resetUserPasswordById(id), {
    onSuccess: () => {
      setClientIndex('');
      toast.success('User password reset successfully, please check your email');
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onSave = () => {
    setOpenDeleteModal(false);
    if (modalMessage === ModalMessageInUserManagement.RESET_PASSWORD) {
      return handleUserResetPassword.mutate(clientIndex);
    }
    if (modalMessage === ModalMessageInUserManagement.DELETE) {
      return handleDelete.mutate(clientIndex);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setClientIndex('');
  };

  const getDate = useCallback((dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm:ss');
  }, []);

  const dataUserList = useMemo(() => {
    return userList?.data[0]?.docs;
  }, [userList]);

  const dataValue = useMemo(() => {
    return userList?.data[0]?.metadata[0];
  }, [userList]);

  const clientManagement = useMemo(() => {
    if (!dataUserList) {
      return [];
    }
    dataUserList.forEach((item: any) => {
      if (item?.clients?.length > 0) {
        item.clientName = item.clients.map((client: any) => client.name).join(', ');
      } else {
        if (item?.isSuperAdmin) {
          item.clientName = 'Super Admin';
        } else if (item?.isSuperUser) {
          item.clientName = 'Super User';
        } else {
          item.clientName = 'No client found';
        }
      }
    });
    return dataUserList.map((item: any) => {
      return item.clientName;
    });
  }, [dataUserList]);

  const roleManagement = useMemo(() => {
    if (!dataUserList) {
      return [];
    }
    dataUserList.forEach((item: any) => {
      if (item.userRoles.length > 0) {
        item.roleName = item.userRoles.map((role: any) => role.name).join(', ');
      } else {
        item.roleName = 'No role found';
      }
    });
    return dataUserList.map((item: any) => {
      return item.roleName;
    });
  }, [dataUserList]);

  const indexStartPage = useMemo(() => {
    return (dataValue?.currentPage - 1) * dataValue?.limit + 1;
  }, [dataValue?.currentPage, dataValue?.limit]);

  const countPage = useMemo(() => {
    return Math.ceil(dataValue?.total / dataValue?.limit);
  }, [dataValue?.limit, dataValue?.total]);

  const indexEndPage = useMemo(() => {
    if (!dataValue) {
      return 0;
    }
    return Math.min(dataValue.currentPage * dataValue.limit, dataValue.total);
  }, [dataValue]);

  const handleChangePageSize = (event: any) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleAddUser = () => {
    setOpenAddUser(true);
  };

  const userCanBeSuperUser = useMemo(() => {
    if (!dataUserList) return [false];
    const value = dataUserList.map((item: any) => {
      if (item.canBecomeSupperUser.length === 0)
        return {
          id: item._id,
          canBecomeSuperUsers: false,
        };
      return {
        id: item._id,
        canBecomeSuperUsers: item.canBecomeSupperUser.includes(true),
      };
    });
    return value;
  }, [dataUserList]);

  const handleAddUserClose = () => {
    setOpenAddUser(false);
    setUserId('');
    setCanBecomeSuperUser(false);
    refetch();
  };

  const handleEditUser = (id: string, index: any) => {
    setOpenAddUser(true);
    setUserId(id);
    userCanBeSuperUser.forEach((item: any) => {
      if (item.id === id) {
        setCanBecomeSuperUser(item.canBecomeSuperUsers);
      }
    });
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
        border: `1px solid ${theme.palette.divider}`,
      }}>
      {!openAddUser ? (
        <>
          {isShowModalShare && (
            <Box
              sx={{
                px: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '74px',
              }}>
              <Typography
                sx={{
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
                  lineHeight: '20px',
                }}>
                {t('dashboard.user_management')}
              </Typography>
              {ableToAdd && (
                <Button
                  startIcon={<Add />}
                  onClick={() => handleAddUser()}
                  sx={{
                    width: '160px',
                    height: '40px',
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#1C9D57',
                      border: `1px solid ${theme.palette.primary.main}`,
                      boxShadow: 'none',
                    },
                    '&:active': {
                      boxShadow: 'none',
                      backgroundColor: '#1C9D57',
                      border: `1px solid ${theme.palette.primary.main}`,
                    },
                    '&:focus': {
                      boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
                      border: `1px solid ${theme.palette.primary.main}`,
                    },
                  }}>
                  {t('trans.add_user')}
                </Button>
              )}
            </Box>
          )}

          <Box sx={{ border: '0.5px solid #EDF1F1' }} />
          {/*  */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: '20px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Typography mr="10px" fontSize="16px" lineHeight="19px" color="#A8AEB1">
                {t('dashboard.show')}
              </Typography>
              <Button
                sx={{
                  height: '40px',
                  border: 'none',
                }}>
                <Select
                  sx={{
                    border: 'none',
                  }}
                  id="demo-simple-select"
                  value={rowsPerPage}
                  onChange={handleChangePageSize}>
                  {rowPerPageOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Button>
            </Box>
            <TextField
              type="text"
              placeholder={t('dashboard.search_users')}
              sx={{
                mr: 1,
                width: '340px',
                minWidth: '340px',
                height: '40px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '.MuiOutlinedInput-root': {
                  height: '40px',
                },
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '5px',
              }}
              onChange={(e) => {
                setSearchClient(e.target.value);
              }}
              InputProps={{
                size: 'small',
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ height: '24px', width: '24px' }} />
                  </InputAdornment>
                ),
                inputProps: {
                  style: {
                    fontFamily: 'Barlow',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                  },
                },
              }}
            />
          </Box>
          <TableContainer sx={{ overflow: 'hidden' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  component="tr"
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    py: '10px',
                  }}>
                  <TableCell component="th" sx={{ ...styles.clientListTitle, minWidth: '200px' }}>
                    {t('trans.name')}
                  </TableCell>
                  {isShowModalShare && (
                    <TableCell component="th" sx={{ ...styles.clientListTitle }}>
                      {t('dashboard.last_activity')}
                    </TableCell>
                  )}

                  {!clientId && isShowModalShare && (
                    <TableCell component="th" sx={{ ...styles.clientListTitle }}>
                      {t('dashboard.client_management')}
                    </TableCell>
                  )}
                  {isShowModalShare && (
                    <TableCell component="th" sx={{ ...styles.clientListTitle }}>
                      {t('dashboard.job_title')}
                    </TableCell>
                  )}
                  {clientId && isShowModalShare && (
                    <TableCell component="th" sx={{ ...styles.clientListTitle }}>
                      {t('dashboard.access')}
                    </TableCell>
                  )}
                  <TableCell component="th" sx={{ ...styles.clientListTitle, textAlign: 'center' }}>
                    {isShowModalShare ? t('dashboard.account_type') : 'Roles'}
                  </TableCell>
                  {(ableToEdit || ableToDelete) && (
                    <TableCell component="th" sx={{ ...styles.clientListTitle, textAlign: 'center' }}>
                      {t('trans.actions')}
                    </TableCell>
                  )}
                  {(ableToEdit || ableToResetPassword) && isShowModalShare && (
                    <TableCell component="th" sx={{ ...styles.clientListTitle, width: '200px' }} />
                  )}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        sx={{
                          padding: '16px 0',
                        }}>
                        <WaitingPageAPI />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </>
              ) : (
                <TableBody
                  sx={{
                    pt: 0,
                    flexDirection: 'column',
                    minHeight: '510px',
                    overflowY: 'scroll',
                    '-ms-overflow-style': 'none' /* IE and Edge */,
                    scrollbarWidth: 'none' /* Firefox */,
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}>
                  {dataUserList && dataUserList.length > 0 ? (
                    dataUserList.map((userDetail: any, index: number) => {
                      return (
                        <TableRow
                          component="tr"
                          sx={{
                            display: 'table-row',
                            flex: 1,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            height: '50px',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#F5F5F5',
                            },
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                          key={index}>
                          <TableCell
                            component="td"
                            sx={{
                              ...styles.clientContainerName,
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar src={userDetail.avatar} sx={{ ...styles.clientAvatar }} />
                              <Box>
                                <Typography
                                  sx={{
                                    ...styles.clientName,
                                  }}>
                                  {userDetail.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...styles.clientName,
                                    fontSize: '14px',
                                  }}>
                                  {userDetail.email}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          {isShowModalShare && (
                            <TableCell
                              component="td"
                              sx={{
                                ...styles.clientUpdateTime,
                                borderBottom: 'none',
                                px: '16px',
                              }}>
                              {getDate(userDetail.updatedAt)}
                            </TableCell>
                          )}

                          {!clientId && isShowModalShare && (
                            <TableCell
                              component="td"
                              sx={{
                                ...styles.clientUpdateTime,
                                borderBottom: 'none',
                                minWidth: '160px',
                                display: 'flex',
                                alignItems: 'center',
                                color:
                                  clientManagement[index] === 'Super Admin'
                                    ? theme.palette.primary.main
                                    : clientManagement[index] === 'No client found'
                                    ? '#A8AEB1'
                                    : theme.palette.mode === 'dark'
                                    ? 'white'
                                    : '#000',
                              }}>
                              {/* Client management */}
                              <Scrollbars
                                style={{
                                  height: '60px',
                                  width: '100%',
                                  padding: '0px',
                                }}
                                autoHide>
                                {clientManagement[index]}
                              </Scrollbars>
                            </TableCell>
                          )}
                          {isShowModalShare && (
                            <TableCell
                              component="td"
                              sx={{
                                ...styles.clientUpdateTime,
                                borderBottom: 'none',
                                px: '16px',
                                color: userDetail.jobTitle
                                  ? theme.palette.mode === 'dark'
                                    ? 'white'
                                    : '#000'
                                  : '#A8AEB1',
                              }}>
                              {userDetail.jobTitle ? userDetail.jobTitle : 'N/A'}
                            </TableCell>
                          )}

                          {clientId && isShowModalShare && (
                            <TableCell
                              component="td"
                              sx={{
                                ...styles.clientUpdateTime,
                                borderBottom: 'none',
                                minWidth: '150px',
                                color:
                                  roleManagement[index] === 'No role found'
                                    ? theme.palette.mode === 'dark'
                                      ? 'white'
                                      : '#000'
                                    : theme.palette.primary.main,
                              }}>
                              <Scrollbars
                                style={{
                                  height: '60px',
                                  width: '100%',
                                  padding: '0px',
                                }}
                                autoHide>
                                {roleManagement[index]}
                              </Scrollbars>
                            </TableCell>
                          )}
                          <TableCell
                            component="td"
                            sx={{
                              px: '16px',
                              border: 'none',
                            }}>
                            <Typography
                              component="p"
                              sx={{
                                ...styles.clientStatus,
                              }}>
                              {userDetail.isSuperAdmin
                                ? t('trans.super_admin')
                                : userDetail.isSuperUser
                                ? t('trans.super_user')
                                : t('trans.user')}
                            </Typography>
                          </TableCell>
                          {(ableToEdit || ableToDelete) && (
                            <TableCell
                              component="td"
                              sx={{
                                px: '16px',
                                border: 'none',
                              }}>
                              <Box
                                sx={{
                                  ...styles.clientActions,
                                  px: '16px',
                                  border: 'none',
                                }}>
                                {!isShowModalShare && <Checkbox />}
                                {ableToEdit && isShowModalShare && (
                                  <IconButton aria-label="edit">
                                    <EditRoundedIcon
                                      sx={{
                                        ...styles.clientActionsIcon,
                                      }}
                                      onClick={() => {
                                        if (userDetail.isSuperAdmin && userDetail._id !== currentUserId) {
                                          toast.error('You can not edit another Super Admin', { toastId: 1 });
                                        } else if (
                                          !isSuperAdmin &&
                                          userDetail.isSuperUser &&
                                          userDetail._id !== currentUserId
                                        ) {
                                          toast.error('You can not edit another Super User', { toastId: 1 });
                                        } else {
                                          handleEditUser(userDetail._id, index);
                                        }
                                      }}
                                    />
                                  </IconButton>
                                )}
                                {ableToDelete && isShowModalShare && (
                                  <IconButton aria-label="delete">
                                    <DeleteRoundedIcon
                                      sx={{
                                        ...styles.clientActionsIcon,
                                      }}
                                      onClick={() => {
                                        if (userDetail.isSuperAdmin && userDetail._id !== currentUserId) {
                                          toast.error('You can not delete another Super Admin', {
                                            toastId: 1,
                                          });
                                        } else if (
                                          !isSuperAdmin &&
                                          userDetail.isSuperUser &&
                                          userDetail._id !== currentUserId
                                        ) {
                                          toast.error('You can not delete another Super User', { toastId: 1 });
                                        } else {
                                          setClientName(userDetail.name);
                                          setClientIndex(userDetail._id);
                                          setModalMessage(ModalMessageInUserManagement.DELETE);
                                          setOpenDeleteModal(true);
                                        }
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </Box>
                            </TableCell>
                          )}
                          {(ableToEdit || ableToResetPassword) && !userDetail.providerId && isShowModalShare && (
                            <TableCell
                              component="td"
                              sx={{
                                px: '16px',
                                border: 'none',
                              }}>
                              <Button
                                startIcon={<CachedIcon fontSize="small" />}
                                onClick={() => {
                                  if (userDetail.isSuperAdmin && userDetail._id !== currentUserId) {
                                    toast.error('You can not reset password another Super Admin', {
                                      toastId: 1,
                                    });
                                  } else if (
                                    !isSuperAdmin &&
                                    userDetail.isSuperUser &&
                                    userDetail._id !== currentUserId
                                  ) {
                                    toast.error('You can not reset password another Super User', { toastId: 1 });
                                  } else {
                                    setClientName(userDetail.name);
                                    setClientIndex(userDetail._id);
                                    setModalMessage(ModalMessageInUserManagement.RESET_PASSWORD);
                                    setOpenDeleteModal(true);
                                  }
                                }}
                                sx={{
                                  ...styles.clientUpdateTime,
                                }}>
                                {t('trans.reset_password')}
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <Typography variant="body1" color="textSecondary">
                          {t('dashboard.no_data_found_or_no_permission')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/*  */}
          {dataUserList && dataUserList.length > 0 && (
            <Box
              sx={{
                ...styles.clientShowTotal,
              }}>
              <Typography>
                Showing {indexStartPage} to {indexEndPage} of {dataValue?.total}{' '}
                {isShowModalShare ? 'clients' : 'users'}
              </Typography>
              <Stack spacing={2}>
                <Pagination
                  sx={{
                    '& .MuiPagination-ul': {
                      '& .Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                      },
                      '& .Mui-selected:hover': {
                        backgroundColor: '#23BE6AB0',
                      },
                      '& .MuiPaginationItem-icon': {
                        color: theme.palette.primary.main,
                      },
                    },
                  }}
                  count={countPage}
                  defaultPage={1}
                  page={page}
                  onChange={(_, value) => {
                    //scroll to titleDialog
                    setPage(value);
                    titleDialog.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                />
              </Stack>
            </Box>
          )}
          <ClientActionsDialog
            title={modalDescription.title}
            open={openDeleteModal}
            onClose={closeDeleteModal}
            description={modalDescription.description}
            saveLabel={modalDescription.saveLabel}
            onCancel={closeDeleteModal}
            onSave={onSave}
            submit={false}
            clientAt={TypeModalDelete.USER_MANAGEMENT}
            clientName={clientName}
            userManagementAction={modalMessage}
          />
        </>
      ) : (
        <UserProfile handleBack={handleAddUserClose} userId={userId} canBecomeSuperUser={canBecomeSuperUser} />
      )}
    </Stack>
  );
};
