import { Grid, InputLabel, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import {
  FILE_TYPE,
  LABEL_DATA_RASTERS,
  LayerTypeEnum,
  mapFileLabel,
  TYPE_ANALYTICS_MAP_VIEW,
} from 'common/defines/constants';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createTreeHeight,
  deleteRasterAnalysisData,
  getRasterDetails,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector } from 'store/slices/analyticsConfigSlice';
import { ErrorsDialog } from './ErrorDialog';

interface ITreeHeightForm {
  sensorId: any;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

export const TreeHeightForm: FC<ITreeHeightForm> = ({ sensorId, deleteFileGeojson }) => {
  const [parentId, setParentId] = useState<string>('');
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();
  const layerType = LayerTypeEnum.CANOPY_HEIGHT_MODEL;

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const { listRasterPagination } = useAppSelector(anaLyticConfigSelector);

  useEffect(() => {
    for (const raster of listRasterPagination) {
      if (
        raster?.analysis?.find(
          (item: any) => item?._id === sensorId && item?.name === TYPE_ANALYTICS_MAP_VIEW.TREE_HEIGHT
        )
      ) {
        setParentId(raster?._id);
        return;
      }
    }
  }, [listRasterPagination, sensorId]);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () =>
    getRasterDetails(sensorId)
  );

  const createAnalyticMutation = useMutation((data: any) => createTreeHeight(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success('Update Tree Height Analytic Successful', { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  useEffect(() => {
    formRef.current && formRef.current.resetForm();
  }, [sensorId, formRef]);

  const initialFields = useMemo(() => {
    let initParam = { mainGeoJsonFile: undefined, intervalLimitFile: undefined, [layerType]: undefined };
    const uploadHistory = analyticDetails?.data?.uploadHistory;
    if (uploadHistory?.length) {
      uploadHistory.forEach((_item: any) => {
        if (_item.fileType === 'GEO_JSON') {
          if (_item.geoJsonType === 'INTERVAL_LIMIT') {
            initParam.intervalLimitFile = _item;
          } else {
            initParam.mainGeoJsonFile = _item;
          }
        } else {
          if (_item.layerType === layerType) {
            initParam[layerType] = _item;
          }
        }
      });
    }
    return initParam;
  }, [analyticDetails?.data?.uploadHistory, layerType]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          !!parentId && formData.append('parentId', parentId);
          formData.append('dataFile', values.mainGeoJsonFile || '');
          formData.append('intervalLimitFile', values.intervalLimitFile || '');
          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <InputLabel sx={{ fontWeight: '600', mt: '24px' }}>Geojson</InputLabel>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {mapFileLabel[FILE_TYPE.TREE_HEIGHT_FILE]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.json$/)}
                    fileSelected={values.mainGeoJsonFile}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('mainGeoJsonFile', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.TREE_HEIGHT_FILE}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {mapFileLabel[FILE_TYPE.INTERVALS_FILE]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.json$/)}
                    fileSelected={values.intervalLimitFile}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('intervalLimitFile', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.INTERVALS_FILE}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>

                {/* Raster area  */}
                <InputLabel sx={{ fontWeight: '600', mt: '64px' }}>Raster</InputLabel>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {LABEL_DATA_RASTERS[layerType]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.zip$/)}
                    fileSelected={values[layerType]}
                    onSelectFile={(file) => setFieldValue(layerType, file)}
                    analysisId={sensorId}
                    layerType={layerType}
                    deleteFileGeojson={deleteRasterAnalysisData}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'}>
                <ButtonCustom
                  title="Save"
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};
