import { FormControlLabel, Grid, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsShowAllInfoTooltip, mapViewSelector } from 'store/slices/mapViewSlice';

export const SwitchLabel = () => {
  const classes = useLayerSelectCardStyle();
  const { isShowAllInfoTooltip } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={12}>
      <FormControlLabel
        control={
          <SwitchCustom
            checked={isShowAllInfoTooltip}
            onClick={() => dispatch(changeIsShowAllInfoTooltip(!isShowAllInfoTooltip))}
          />
        }
        label={
          <Typography classes={{ root: classes.selectText }}>
            {' '}
            {isShowAllInfoTooltip ? t('trans.all') : t('trans.single')}
          </Typography>
        }
      />
    </Grid>
  );
};
