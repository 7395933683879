import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import { Tooltip, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useNavigate } from 'react-router-dom';
import { DrawerHeader } from './DrawerHeader';
import { HeaderDashboard } from './header-dashboard';
import { NavBar } from './NavBar';

const drawerWidth = 256;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AppLayoutStyle = makeStyles((theme: any) => ({
  listItemBtn: (open) => ({
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    mx: open ? 0 : -1,
    cursor: 'pointer',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper + '99' : 'transparent',
  }),
  listItemIcon: (open) => ({
    minWidth: 42,
    mr: open ? 3 : 'auto',
    justifyContent: 'center',
  }),
  listItemText: (open) => ({
    opacity: open ? 1 : 0,
    transition: 'opacity 0.2s ease-in-out',
  }),
}));

export default function AppLayout(props: { children: JSX.Element | JSX.Element[]; hidden?: boolean }) {
  const { children, hidden } = props;
  const [open, setOpen] = useState<boolean>(false);
  const styles = AppLayoutStyle(open);
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        background: theme.palette.background.default,
        minHeight: '100vh',
      }}>
      <Box sx={{ display: hidden ? 'none' : 'unset' }}>
        <AppBar
          position="fixed"
          open={open}
          sx={{
            background: theme.palette.background.default,
            boxShadow: 'none',
          }}>
          <Toolbar
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
              boxShadow: 'none',
            }}>
            <HeaderDashboard onClick={() => setOpen((v) => !v)} open={open} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              backgroundColor: theme.palette.mode === 'dark' ? '#222631' : '',
            }}>
            <NavBar open={open} />
          </Box>
          <Divider />
          <List disablePadding={true}>
            <ListItemButton className={styles.listItemBtn} onClick={() => navigate('/')}>
              <ListItemIcon className={styles.listItemIcon}>
                <Tooltip title="Exit">
                  <ListAltRoundedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Landing Page" className={styles.listItemText} />
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, height: 'fit-content', width: '100vw' }}>
        <Scrollbars style={{ height: '100vh', width: '100%' }}>
          <DrawerHeader />
          {children}
          <Box
            sx={{
              height: '10px',
            }}
          />
        </Scrollbars>
      </Box>
    </Box>
  );
}
