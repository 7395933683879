import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, styled, Typography, useTheme } from '@mui/material';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getStandCountBoundary } from 'services/clients/apiClient.services';
import { saveColorsAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeColorAnalyticLayers,
  changeIsShowStandCountBoundary,
  changeLayer3D,
  changeVisibleStandCountAllLayers,
  changeVisibleStandCountLayer,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import AbilityDownload from '../AbilityDownload';
import { PolygonLayer } from '../PolygonLayer';
import SideSurface from '../SideSurface';
import TopSurface from '../TopSurface';

export const StandCountCardLayer = ({ labelCard }: any) => {
  const [isExistStandCountBoundary, setIsExistStandCountBoundary] = useState<boolean>(false);
  const theme = useTheme();

  const ButtonSubmit = styled(Button)((a) => {
    return {
      width: '100%',
      height: '40px',
      borderRadius: '5px',
      padding: '12px 10px',
      marginBottom: '30px',
      textTransform: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',
      backgroundColor: `${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
        border: `1px solid ${theme.palette.primary.main}`,
      },
    };
  });

  const {
    isShowAllLayer,
    isLayer3D,
    dataStandCount: data,
    analyticId,
    isShowStandCountBoundary,
  } = useAppSelector(mapViewSelector);
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [raster, setRaster] = useState(true);
  const { clientId } = useParams();
  const { t } = useTranslation();

  const [isChangeColor, setIsChangeColor] = useState(false);

  useQuery([QUERY_KEY.STAND_COUNT_BOUNDARY, analyticId], () => getStandCountBoundary(analyticId!), {
    onSuccess(res) {
      if (res.data) {
        setIsExistStandCountBoundary(true);
      }
    },
    enabled: !!analyticId,
  });

  const { mutate } = useMutation(
    (data: { analysisId: string; settingId: string; color: any }) => saveColorsAnalyticLayer(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([QUERY_KEY.COLOR_ANALYTIC_LAYER, clientId, analyticId]).then(() => {}),
    }
  );

  const filterDownload = useMemo(() => {
    const downloadData: any[] = [];
    if (!data) {
      return [];
    }

    const allData = data.map((item: any) => {
      return {
        ...item,
        geometry: {
          type: 'MultiPolygon',
          coordinates: item.data,
        },
      };
    });
    allData.forEach((newData: any) => {
      if (newData.visible) {
        downloadData.push(newData);
      }
    });
    return downloadData;
  }, [data]);

  return (
    <>
      {data?.length > 0 && (
        <Grid sx={{ mt: 1 }}>
          <Button
            startIcon={raster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setRaster(!raster)}
            classes={{ root: classes.buttonTextStyle }}>
            {t(convertAnalyticNameToMultiLanguage(labelCard))}
          </Button>
          <Collapse in={raster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                  label={
                    <Typography className={classes.selectText}>
                      {isLayer3D ? t('trans.3d_fill') : t('trans.2d_fill')}
                    </Typography>
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                  }}>
                  {filterDownload.length > 0 && (
                    <AbilityDownload dataPoints={filterDownload} type={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT} />
                  )}
                </Box>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isShowAllLayer}
                      onClick={() => dispatch(changeVisibleStandCountAllLayers(!isShowAllLayer))}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Typography className={classes.selectText}>
                        {isShowAllLayer ? t('trans.unselect_all') : t('trans.select_all')}
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
              <>
                {data.map((category: any, index: number) => {
                  return (
                    <PolygonLayer
                      key={index}
                      label={category.status}
                      color={category.color}
                      id={category.status}
                      dataPoints={category.data}
                      visible={category.visible}
                      changeColorAnalyticsLayer={changeColorAnalyticLayers}
                      changeVisibleAnalyticsLayer={changeVisibleStandCountLayer}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT}
                      callback={() => setIsChangeColor(true)}
                    />
                  );
                })}

                {isExistStandCountBoundary && (
                  <FormControlLabel
                    control={
                      <SwitchCustom
                        checked={isShowStandCountBoundary}
                        onClick={() => dispatch(changeIsShowStandCountBoundary(!isShowStandCountBoundary))}
                      />
                    }
                    sx={{ mt: '8px' }}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Typography className={classes.selectText}>{t('trans.stand_count_boundary')}</Typography>
                      </Box>
                    }
                  />
                )}

                {isChangeColor && (
                  <Grid container top={2} paddingTop={2} justifyContent={'center'} gap={2} xs={12}>
                    <Grid item xs={4}>
                      <Button
                        color="neutral"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          height: '40px',
                          borderRadius: '5px',
                          p: '12px 10px',
                          mb: '30px',
                          textTransform: 'none',
                          border: `1px solid ${theme.palette.divider}`,
                          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        }}
                        onClick={() => {
                          setIsChangeColor(false);
                          queryClient
                            .invalidateQueries([QUERY_KEY.COLOR_ANALYTIC_LAYER, clientId, analyticId])
                            .then(() => {});
                        }}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonSubmit
                        onClick={() => {
                          setIsChangeColor(false);
                          const colors = data.map((item: any) => item.color);
                          const clientSetting = queryClient.getQueryData([
                            QUERY_KEY.CLIENT_SETTINGS_BY_ID,
                            clientId,
                          ]) as any;
                          if (analyticId && clientSetting) {
                            mutate({ analysisId: analyticId, settingId: clientSetting._id, color: colors });
                          }
                        }}>
                        Save
                      </ButtonSubmit>
                    </Grid>
                  </Grid>
                )}
              </>
            </Grid>
          </Collapse>

          <Grid item xs={12} md={12}>
            {isLayer3D && <SideSurface />}
          </Grid>

          <TopSurface />
        </Grid>
      )}
    </>
  );
};
