import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { Button, Grid, styled, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
const ButtonCustom = styled(Button)(({ theme }) => ({
  ...theme.mixins.toolbar,
  minWidth: '30px !important',
  height: '30px !important',
  minHeight: '30px !important',
  color: '#3C4043 !important',
  backgroundColor: '#FFF !important',
  border: 0,
  borderRadius: '5px',
  fontSize: '16px',
  boxShadow: `0px 2px 10px ${theme.palette.color.grey5}`,
}));

const toolMapViewStyle = makeStyles((theme: any) => ({
  iconInfo: {
    color: theme.palette.mode === 'dark' ? '#3C4043' : theme.palette.color.black2,
  },
}));

export const ToolMapView = ({ showAnalytics, openAnalytic }: any) => {
  const classes = toolMapViewStyle();
  const { t } = useTranslation();

  return (
    <Grid
      className="tool_map_icon"
      container
      spacing={1}
      zIndex={9}
      sx={{
        position: 'absolute',
        ml: showAnalytics ? 'calc(100% - 80px)' : 'calc(100% - 65px)',
        mt: '0',
        width: 'auto',
      }}
      justifyContent="flex-end"
      columnSpacing={showAnalytics ? 0 : 2}>
      {!showAnalytics && (
        <Grid item xs={12}>
          <Tooltip title={t('trans.analytical_information')}>
            <ButtonCustom
              sx={{
                width: '30px !important',
              }}
              onClick={() => openAnalytic(true)}>
              <AutoAwesomeMosaicIcon className={classes.iconInfo} />
            </ButtonCustom>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};
