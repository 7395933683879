import { Box, Button, Grid, InputLabel, Skeleton, Typography, useTheme } from '@mui/material';
import { RenderTree } from 'common/defines/clients';
import { flatten, interLeaveItemInArray, setValueByDynamicPath } from 'common/utils/util';
import { ButtonCropType } from 'components/Common/ButtonCropType';
import { QUERY_KEY } from 'constants/constants';
import { useCreateTreeLevel } from 'hooks/useClientProperties';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getClientById, getLevelListByCropType } from 'services/clients/apiClient.services';
import { getAllSettingsDataCroptype } from 'services/settings/settings.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeCropType, changeLevelId, mapViewSelector } from 'store/slices/mapViewSlice';
import { clearVigorAnalytics } from '../../../store/slices/map-view/vigorAnalytics';
import { LevelTree } from './LevelTree';

export const HomeLocation = (props: any) => {
  const { onDashboard } = props;
  const { clientId } = useParams();
  const { cropType } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);
  const scrollBlockRef = useRef() as any;
  const bottomBock = useRef() as React.MutableRefObject<HTMLDivElement>;
  const theme = useTheme();
  const { t } = useTranslation();

  const mutationCreateTreeLevel = useCreateTreeLevel();
  const { data: cropTypeData } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE], () => getAllSettingsDataCroptype());
  const dataSettingsAnalyticsValue = useMemo(() => {
    return cropTypeData?.data || [];
  }, [cropTypeData]);

  const setLevelId = useCallback(() => {
    dispatch(changeLevelId({ levelId: undefined, isLevelLasted: undefined }));
    //reset vigor analytic
    dispatch(clearVigorAnalytics());
  }, [dispatch]);

  const setCropTypeSelected = useCallback(
    (type?: string) => {
      dispatch(changeCropType(type));
    },
    [dispatch]
  );

  const { data: clientData } = useQuery([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId || ''), {
    enabled: !!clientId,
  });

  const {
    data: levelList,
    isFetching,
    refetch: refetchLevelTree,
    remove: removeCacheLevelList,
  } = useQuery([QUERY_KEY.LEVEL_LIST, cropType], () => getLevelListByCropType(clientId, cropType), {
    enabled: !!cropType && !!clientId,
    keepPreviousData: false,
  });
  const [levelStateList, setLevelStateList] = useState<RenderTree[]>();

  useEffect(() => {
    if (!isFetching && levelList) {
      setLevelStateList(levelList);
    }
  }, [isFetching]);

  useEffect(() => {
    removeCacheLevelList();
    setCropTypeSelected();
    setLevelId();
  }, [clientId, setCropTypeSelected, setLevelId, removeCacheLevelList]);

  const onCreateLevel = (parent: string | null, clientIdParam: string, cropTypeParam: any, name: string) =>
    mutationCreateTreeLevel.mutate(
      { parent, clientId: clientIdParam, cropType: cropTypeParam, name },
      {
        onSuccess: (res: any) => {
          if (res?.data?.status === 400) {
            toast.error(res.data.response.message, { toastId: 1 });
            return;
          }
          refetchLevelTree();
        },
      }
    );

  const cropTypeOptions = useMemo(() => {
    return clientData?.cropType || [];
  }, [clientData]);

  const maxLevel = useMemo(() => {
    if (!clientData || !cropType) {
      return 0;
    }

    return clientData.numberOfLevel?.find((item: any) => item.type === cropType)?.value || 0;
  }, [clientData, cropType]);

  // Reduce a N-dimensional [array {object}] to Object with Id and its index as key
  const reduceArrayObjectToObj = useCallback(
    (array: RenderTree[]) => {
      return array.reduce((obj: any, item: RenderTree, index: number) => {
        obj[`${item._id.slice(-4)}_index_${index}`] =
          item.level === maxLevel - 1
            ? (obj[`${item._id.slice(-4)}_index_${index}`] = item.children)
            : item.children
            ? reduceArrayObjectToObj(item.children)
            : '';
        return obj;
      }, {});
    },
    [maxLevel]
  );

  const getKeyByValue = useCallback((object: any, value: string) => {
    return Object.keys(object).find((key) => object[key] === value) || '';
  }, []);

  const reduceLevelList = useMemo(() => {
    return !isFetching && levelList
      ? { reducedObj: reduceArrayObjectToObj(levelList), baseList: levelList }
      : { baseList: [] };
  }, [isFetching, levelList, reduceArrayObjectToObj]);

  // Slide string path of key and index as array of index
  const convertKeyPathToIndexPath = (keyPath: string) => {
    let finalIndexPath: number[] = [];
    const splitKeyPath = keyPath.split('.');
    splitKeyPath.forEach((element) => {
      finalIndexPath.push(Number(element.split('_')[element.split('_').length - 1]));
    });
    return finalIndexPath;
  };

  const getSearchedParentItem = useCallback(
    (currentNodeId: string) => {
      let searchedParentItem: RenderTree = reduceLevelList.baseList[0];

      // get key of flatten object which have value as currentNodeId of parent searched array
      const entriesObjectPath: string = getKeyByValue(flatten(reduceLevelList.reducedObj), currentNodeId)
        ?.split('.')
        .slice(0, -2)
        .join('.');

      const pathIndexToSearchedItem = convertKeyPathToIndexPath(entriesObjectPath);

      pathIndexToSearchedItem.forEach((element: number, index: number) =>
        index === 0
          ? (searchedParentItem = reduceLevelList.baseList[element])
          : searchedParentItem.children
          ? (searchedParentItem = searchedParentItem.children[element])
          : ''
      );
      // to return: searchedParentItem: the parent of searched Array
      // pathIndexToSearchedItem: the path index apply to levelList -> get into the searched index without looping
      return { searchedParentItem, pathIndexToSearchedItem };
    },
    [getKeyByValue, reduceLevelList]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callBackSearching = useCallback(
    debounce((currentNodeId: string, searchString?: string) => {
      if (!isFetching && levelStateList) {
        const result = getSearchedParentItem(currentNodeId);

        // filter by input "searchString"
        const filteredArray = result.searchedParentItem.children?.filter(
          (str: RenderTree) => str.name.toLowerCase().indexOf(searchString ? searchString.toLowerCase() : '') > -1
        );

        // with multi-dimensional arrays you can't use  spread operator to clone the lower level will be treated as referenced
        const cloneList: RenderTree[] = cloneDeep(levelStateList);

        // insert "children" between each item to match with levelList construction [ { children: [{...}] } ]
        const insertedArray = interLeaveItemInArray(result.pathIndexToSearchedItem, 'children');

        //set filteredArray into List by path of reference on cloneList
        if (!isEmpty(filteredArray) && filteredArray) {
          setValueByDynamicPath(cloneList, insertedArray, filteredArray);
        }
        setLevelStateList(cloneList);
      }
    }, 200),
    [levelStateList, isFetching, getSearchedParentItem]
  );

  const addNewLevelHierarchyStructure = (parent: string | null) => {
    if (cropType && clientId) {
      onCreateLevel(parent, clientId, cropType, 'Untitled');
    }
  };

  const skeletonProperties = useMemo(() => {
    return (
      <Typography component="ul" sx={{ pl: 0, pt: 2, padding: 2 }}>
        {Array.from(Array(4).keys()).map((item) => (
          <Box key={item}>
            <Skeleton variant="text" width="100%" height="30px" />
          </Box>
        ))}
      </Typography>
    );
  }, []);

  const test = useMemo(() => {
    return dataSettingsAnalyticsValue.filter((item: any) => cropTypeOptions.some((val: any) => val._id === item._id));
  }, [dataSettingsAnalyticsValue, cropTypeOptions]);

  return (
    <Grid direction="row">
      {isEmpty(cropTypeOptions) && (
        <InputLabel sx={{ color: '#9E9E9E', fontWeight: 400, fonSize: '14px', textTransform: 'unset' }}>
          You have not selected any type yet
        </InputLabel>
      )}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
        {test
          .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
          .map((item: any) => (
            <Grid item xs={6} key={item._id}>
              <ButtonCropType
                isSelected={item._id === (cropType || '')}
                label={item.name}
                icon={item.icon}
                selectedCropType={() => {
                  if (cropType !== item._id) {
                    setCropTypeSelected(item._id);
                  } else {
                    setCropTypeSelected();
                  }
                  setLevelId();
                }}
              />
            </Grid>
          ))}
      </Grid>
      <Grid>
        {isFetching && skeletonProperties}
        {!isFetching && cropType && (
          <Scrollbars
            ref={scrollBlockRef}
            style={{ height: onDashboard ? '60vh' : 'calc(100vh - 200px)' }}
            autoHide
            onScrollFrame={(values: any) => {
              if (values.top === 1 || values.top === 0) {
                setIsShowMoreBtn(false);
              } else {
                setIsShowMoreBtn(true);
              }
            }}>
            <LevelTree
              levels={levelStateList || []}
              maxLevel={maxLevel}
              onNodeAdded={addNewLevelHierarchyStructure}
              callBackSearching={callBackSearching}
            />
            <div ref={bottomBock}></div>
            <Box
              sx={{
                display: isShowMoreBtn ? 'block' : 'none',
                width: '100px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                backgroundColor: 'transparent',
                position: 'fixed',
                bottom: '28px',
                opacity: isShowMoreBtn ? '1' : '0',
                transition: 'all 0.3s ease',
                left: 0,
                right: 0,
              }}>
              <Button
                sx={{
                  maxWidth: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: (theme) => theme.palette.background.paper,
                  borderRadius: '5px',
                  padding: '5px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
                  margin: 'auto',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: '#fff',
                  },
                }}
                onClick={() => {
                  scrollBlockRef.current.view.scroll({
                    top: 10000,
                    behavior: 'smooth',
                  });
                }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    width: '200px',
                  }}>
                  {t('trans.more')}
                </Typography>
              </Button>
            </Box>
          </Scrollbars>
        )}
      </Grid>
    </Grid>
  );
};
