import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { convertAnalyticNameToMultiLanguage } from 'common/utils/convert';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeColorAnalyticLayers,
  changeIsDisplayBoundary,
  changeLayer3D,
  changeVisibleWatershedBasinAllLayers,
  changeVisibleWatershedBasinLayer,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { PolygonLayer } from '../PolygonLayer';
import SideSurface from '../SideSurface';
import TopSurface from '../TopSurface';

export const WatershedBasinCardLayer = ({ labelCard }: any) => {
  const {
    isLayer3D,
    isShowAllLayer,
    isDisplayBoundaryLine,
    watershedBasinAnalytics: data,
  } = useAppSelector(mapViewSelector);
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [raster, setRaster] = useState(true);

  return (
    <>
      {data?.length > 0 && (
        <Grid sx={{ mt: 1 }}>
          <Button
            startIcon={raster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setRaster(!raster)}
            classes={{ root: classes.buttonTextStyle }}>
            {t(convertAnalyticNameToMultiLanguage(labelCard))}
            <Typography variant="caption" color="inherit" classes={{ root: classes.numberDataStyle }}>
              {data.length}
            </Typography>
          </Button>
          <Collapse in={raster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isDisplayBoundaryLine}
                      onClick={() => dispatch(changeIsDisplayBoundary(!isDisplayBoundaryLine))}
                    />
                  }
                  label={<Typography className={classes.selectText}>{t('trans.2d_contour')}</Typography>}
                />
              </Grid>
              {!isDisplayBoundaryLine && (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                    label={
                      <Typography className={classes.selectText}>
                        {isLayer3D ? t('trans.3d_fill') : t('trans.2d_fill')}
                      </Typography>
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.color.grey3}`,
                  margin: '10px 0',
                }}></Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isShowAllLayer}
                      onClick={() => dispatch(changeVisibleWatershedBasinAllLayers(!isShowAllLayer))}
                    />
                  }
                  label={
                    <Typography className={classes.selectText}>
                      {isShowAllLayer ? t('trans.unselect_all') : t('trans.select_all')}
                    </Typography>
                  }
                />
              </Grid>
              <>
                {data.map((value: any, index: number) => {
                  return (
                    <PolygonLayer
                      key={index}
                      label={value.type}
                      color={value.color}
                      id={value._id}
                      visible={value.visible}
                      changeColorAnalyticsLayer={changeColorAnalyticLayers}
                      callback={() => {}}
                      changeVisibleAnalyticsLayer={changeVisibleWatershedBasinLayer}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.WATERSHED_BASIN}
                    />
                  );
                })}
              </>
            </Grid>
          </Collapse>
        </Grid>
      )}
      {isLayer3D && <SideSurface />}
      <TopSurface />
    </>
  );
};
