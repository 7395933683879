import { Box, FormControl, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JapanFlag from '../../../assets/icons/flags/japan.svg';
import UnitedKingdom from '../../../assets/icons/flags/united_kingdom.svg';

const SelectLanguage = () => {
  const [language, setLanguage] = useState<string>('en');
  const { i18n } = useTranslation();
  const theme = useTheme();
  const localLanguage = localStorage.getItem('localLanguage');

  const listLanguageOptions = [
    { key: 1, value: 'en', label: 'En', flag: UnitedKingdom },
    { key: 2, value: 'jp', label: 'Ja', flag: JapanFlag },
  ];

  useEffect(() => {
    if (localLanguage) {
      setLanguage(localLanguage);
    }
  }, [localLanguage]);

  const handleChangeLanguage = (event: any) => {
    const value = event.target?.value as string;
    setLanguage(value);
    localStorage.setItem('localLanguage', value);
    i18n.changeLanguage(value);
  };
  return (
    <FormControl sx={{ width: '100px', mr: '16px' }}>
      <Select
        value={language}
        onChange={handleChangeLanguage}
        size="small"
        sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.color.grey : '' }}>
        {listLanguageOptions.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <img src={item.flag} alt="flag" height="14" />
              <Box component="span" sx={{ ml: '8px' }}>
                <Typography fontSize="18px" fontWeight={600}>
                  {item.label}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectLanguage;
