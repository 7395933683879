import { AddCircle } from '@mui/icons-material';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { QUERY_KEY } from 'constants/constants';
import { useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  deleteClientByIdReactQuery,
  getClientInSummaryList,
  getClientListByPagination,
  getClientSummary,
  queryMe,
} from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';
import { IClientData, IFormType } from '../../common/defines/clients';
import { setClientData } from '../../store/slices/clientSlice';
import { ClientActionsDialog } from '../ClientsInfo/ClientActionsDialog';
import { ClientCard } from '../ClientsInfo/ClientCard';
import ClientListDialog from '../ClientsInfo/ClientListDialog';
import CardItem from './CardItem';
import S3Usage from './S3Usage/S3Usage';

const data = [
  {
    id: 0,
    icon: '',
    title: 'Total Clients:',
    // name: 'Palm Oil',
    details: 'Total hectarage:',
    area: 0,
    hectarage: 0,
    ableToEdit: true,
  },
];

const Overview = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openClientList, setOpenClientList] = useState(false);
  const [clientIndex, setClientIndex] = useState('');
  const [openClientDashboard, setOpenClientDashboard] = useState(false);

  const [listIdClients, setListIdClients] = useState<string[]>([]);
  const [dataSummaryClients, setDataSummaryClients] = useState<any>({
    data: {
      totalClients: 0,
      totalArea: 0,
    },
  });

  const { data: clientData } = useQuery(QUERY_KEY.CLIENT_LIST, () => getClientListByPagination(1, 10, ''), {
    keepPreviousData: false,
  });

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const sumAreaMode = useMemo(() => {
    return userInfo?.data.sumAreaMode;
  }, [userInfo]);

  useQuery([QUERY_KEY.CLIENT_LIST_IN_SUMMARY], () => getClientInSummaryList(), {
    onSuccess: (data) => {
      if (data) {
        const checkedListId = data.data;
        setListIdClients(checkedListId);
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const { isRefetching: isClientSummaryRefetching } = useQuery(
    [QUERY_KEY.CLIENT_SUMMARY, listIdClients],
    () =>
      getClientSummary({
        ids: listIdClients,
        sumAreaMode,
      }),
    {
      enabled: listIdClients.length > 0,
      keepPreviousData: true,
      onSuccess: (data) => {
        setDataSummaryClients(data);
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const styles = {
    buttonView: {
      p: 0,
      textTransform: 'none',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#FFFFFF',
      padding: '6px 17px',
      borderRadius: '17px',
    },
    userCardStyles: {
      minWidth: '120px',
      width: '120px',
      height: '120px',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius: '10px',
      boxShadow: 1,
      textTransform: 'none',
    },
    userNameStyles: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#616161',
      textAlign: 'center',
    },
    avatarStyles: {
      height: '50px',
      width: '50px',
      m: 'auto',
      mt: 1.75,
      mb: 1.5,
    },
  };

  const handleDelete = useMutation((id: string) => deleteClientByIdReactQuery(id), {
    onSuccess: () => {
      setOpenDeleteModal(false);
      setClientIndex('');
      queryClient.invalidateQueries(QUERY_KEY.CLIENT_DETAILS);
      queryClient.invalidateQueries(QUERY_KEY.CLIENT_LIST);
    },
  });

  const onSave = () => {
    handleDelete.mutate(clientIndex);
  };

  const clientsDataMap = useMemo(() => clientData?.data?.data, [clientData]);

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setClientIndex('');
  };

  const handleOpenClientList = () => {
    setOpenClientList(true);
  };

  const handleCloseClientList = () => {
    setOpenClientList(false);
  };
  return (
    <Box
      sx={{
        position: 'relative',
      }}>
      <Box
        sx={{
          px: '28px',
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: '-2.5rem',
            right: '1rem',
          }}>
          <Box>
            <Button
              sx={{
                ...styles.buttonView,
              }}
              variant="contained"
              color="primary"
              onClick={handleOpenClientList}>
              View all Clients
            </Button>
            <IconButton
              sx={{
                padding: 0,
                textTransform: 'none',
                fontFamily: 'Barlow',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                width: '24px',
                height: '24px',
                marginLeft: '14px',
                boxShadow: '0px 2px 10px rgba(97, 97, 97, 0.1)',
              }}
              onClick={() => {
                setOpenClientDashboard(!openClientDashboard);
              }}>
              <ArrowDropDownCircleRoundedIcon
                sx={{
                  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#A8AEB1',
                  backgroundColor: 'transparent',
                  transform: openClientDashboard ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </IconButton>
          </Box>
          <ClientListDialog deleteClient={handleDelete} open={openClientList} onClose={handleCloseClientList} />
        </Box>
        <Box
          sx={{
            pb: 1.5,
            flexDirection: 'row',
            alignItems: 'center',
          }}
          display={openClientDashboard ? 'flex' : 'none'}>
          <Button
            sx={{
              ...styles.userCardStyles,
              border: theme.palette.mode === 'dark' ? '1px dashed #FFFFFF' : '1px dashed #CECECE',
              boxSizing: 'border-box',
              backgroundColor: theme.palette.mode === 'dark' ? '#1F1F1F' : '#FCFCFC',
              boxShadow: 0,
            }}
            onClick={() => {
              dispatch(setClientData(null));
              navigate('/create-clients', { state: IFormType.ADD });
              dispatch(changeBreadScrumbsSelected('Create Clients'));
            }}>
            <Box>
              <AddCircle sx={{ ...styles.avatarStyles, fill: theme.palette.mode === 'dark' ? '#FFFFFF' : '#CECECE' }} />
              <Typography sx={{ ...styles.userNameStyles }}>Add Client</Typography>
            </Box>
          </Button>
          <Scrollbars
            style={{
              height: '136px',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
            autoHide>
            <Stack
              spacing={3.75}
              sx={{
                flex: 1,
                ml: 3.75,
                px: 1,
                py: 1,
              }}
              direction="row">
              {clientsDataMap &&
                clientsDataMap.map((clientDetail: IClientData) => {
                  return (
                    <ClientCard
                      key={clientDetail._id}
                      clientDetail={clientDetail}
                      setClientIndex={setClientIndex}
                      setOpenDeleteModal={setOpenDeleteModal}
                    />
                  );
                })}
            </Stack>
          </Scrollbars>
          {/* delete */}
          <ClientActionsDialog
            title="Delete Client"
            open={openDeleteModal}
            onClose={closeDeleteModal}
            description="Are you sure about that?"
            onCancel={closeDeleteModal}
            onSave={onSave}
            submit={false}
          />
        </Box>
      </Box>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <Grid item xs={12} sm={12} lg={9}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                {data &&
                  data.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} lg={4}>
                      <CardItem
                        item={item}
                        otherData={dataSummaryClients}
                        isClientSummaryRefetching={isClientSummaryRefetching}
                        listIdClients={listIdClients}
                        setListIdClients={setListIdClients}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                <Grid item xs={12} sm={12} lg={12}>
                  <S3Usage userId={userInfo?.data?._id} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;
